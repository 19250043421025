import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { animated } from "react-spring";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import Button from "../../Atoms/Button";
import Image from "../../Atoms/Image";
import RichText from "../../Atoms/RichText";
import Link from "../../Atoms/Link";
import { Noto, OswaldH2, NotoBody } from "../../Atoms/Typography/Typography";
import { useTranslation } from "react-i18next";
import ArticleIcon from "../../Organisms/Article/ArticleIcon";

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndexes.bottom};
  ${({ animate }) =>
    animate &&
    css`
      transition: transform 600ms ease-out;
    `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: "";
    display: ${({ type }) => (type === "Podcast" ? "none" : "block")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      #000000 0%,
      rgba(0, 0, 0, 0.3) 70%,
      rgba(0, 0, 0, 0.6) 100%
    );
    opacity: 0.6;
    transition: opacity 300ms ease-out;
  }
`;

const ContentWrapper = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: none;
`;

const CTAWraper = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: ${scaleSpacing(6)};

  ${({ animate }) =>
    !!animate &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform 300ms 50ms ease-out;
      transform: translateY(200%);
    `}
`;

const WidthWrapper = styled(animated.div)`
  width: 300px;

  ${mediaquery.sm(css`
    width: calc(var(--col) * 9 - var(--gutter-size));
  `)}

  ${mediaquery.md(css`
    width: calc(var(--col) * 7 - var(--gutter-size));
  `)}

  ${mediaquery.l(css`
    width: calc(var(--col) * 5 - var(--gutter-size));
  `)}

  ${mediaquery.xxl(css`
    width: 320px;
  `)};

  ${({ animate }) =>
    animate &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          ${Cover} {
            transform: scale(1.1);
            transition: transform 300ms ease-out;
            &:after {
              opacity: 0;
            }
          }
        }
      }
    `}

  ${({ showbutton }) =>
    showbutton &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          ${ContentWrapper} {
            transform: translateY(-72px);
            transition: transform 300ms ease-in-out;
          }

          ${CTAWraper} {
            transform: translateY(0);
            transition: transform 300ms 50ms ease-in-out;
          }
        }
      }

      @media (hover: none), (pointer: coarse) {
        ${ContentWrapper} {
          transform: translateY(-72px);
        }

        ${CTAWraper} {
          transform: translateY(0);
        }
      }
    `}
`;

const WrapperOutside = styled.div`
  height: 0;
  overflow: hidden;
  position: relative;
  background: transparent;
  border-radius: 10px;

  /* Fix safari radius overflow issue */
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${({ imageRatio }) =>
    imageRatio &&
    css`
      padding-top: calc(${imageRatio} * 100%);
    `}
`;

const WrapperInside = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: ${zIndexes.top};
  width: ${`calc(100% - ${scaleSpacing(8)})`};
  height: ${`calc(100% - ${scaleSpacing(8)})`};

  ${mediaquery.sm(css`
    width: ${`calc(100% - ${scaleSpacing(12)})`};
    height: ${`calc(100% - ${scaleSpacing(12)})`};
  `)};
`;

const Title = styled.span`
  display: block;
  padding-right: ${scaleSpacing(12)};
  color: ${colors.white};
  margin: ${scaleSpacing(2)} 0;
  ${OswaldH2};
`;

const Description = styled.span`
  display: block;
  color: ${colors.white};
  margin: 0;
  ${NotoBody};
  padding-right: ${scaleSpacing(12)};
`;

const Copyright = styled.p`
  color: ${colors.white};
  position: absolute;
  right: 0;
  margin: 0;
  transform: rotate(-90deg);
  transform-origin: top right;
  ${Noto};
  font-size: ${rem(10)};
  line-height: ${rem(18)};
`;

const CardDetail = styled(Button)`
  padding: ${scaleSpacing(5)} ${scaleSpacing(6)};
  ${NotoBody};
  color: white;
  text-decoration: underline;
`;

const SoundButtonContainer = styled.button`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:before {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: ${scaleSpacing(6)};
  bottom: ${scaleSpacing(6)};
  margin: auto;
  z-index: ${zIndexes.top};
  justify-content: center;
  span {
    width: ${rem(8)};
  }
  .soundIcon {
    display: flex;
    width: auto;
  }

  ${mediaquery.md(css`
    span {
      width: ${rem(14)};
    }
  `)};
`;

const Card = ({
  topic,
  title,
  free,
  description,
  ctaLink,
  ctaLabel,
  detailsLabel,
  details,
  onClickDetails,
  credits,
  image,
  imageRatio,
  animate,
  animatedStyles,
  showButton,
  ctaButtonTheme,
  type,
  category,
}) => {
  const showPopin = e => {
    e.preventDefault();
    onClickDetails(details);
  };

  const { t } = useTranslation();
  return (
    <WidthWrapper
      animate={animate ? 1 : 0}
      showbutton={showButton ? 1 : 0}
      style={animatedStyles}
      className="parentWrapper"
    >
      <WrapperOutside imageRatio={imageRatio}>
        <Cover animate={animate} className="cover" type={type}>
          {image && <Image source={image} />}
        </Cover>
        <WrapperInside>
          {!!topic && (
            <Button
              css={css`
                align-self: flex-start;
              `}
              buttonTheme="tagCuriosity"
            >
              {topic}
            </Button>
          )}
          <ContentWrapper link={ctaLink} linkTheme="area">
            {!!free && (
              <Button
                css={css`
                  border-radius: ${rem(15)};
                  padding: ${rem(3)} ${rem(8)} ${rem(5)};
                  margin-bottom: ${rem(16)};
                  line-height: ${rem(12)};
                  text-transform: none;
                  width: ${rem(60)};
                `}
                buttonTheme="tagRed"
              >
                {t("curiosity_herowithcarousel_free")}
              </Button>
            )}
            {title && (
              <Title>
                <RichText
                  data={title}
                  clamp={type == "Portrait" || type == "article" ? 4 : 3}
                />
              </Title>
            )}
            {description && (
              <Description>
                <RichText data={description} clamp={2} />
              </Description>
            )}
          </ContentWrapper>
          <CTAWraper animate={animate}>
            {ctaLabel && (
              <Button buttonTheme={ctaButtonTheme} link={ctaLink}>
                {ctaLabel}
              </Button>
            )}
            {details && (
              <CardDetail buttonTheme="tertiary" onClick={showPopin}>
                {detailsLabel}
              </CardDetail>
            )}
          </CTAWraper>
          {credits && <Copyright>{credits}</Copyright>}
        </WrapperInside>
        {category && (
          <SoundButtonContainer ariaLabel="play">
            <StyledButton buttonTheme="iconAlpha" as="div" type="">
              <ArticleIcon format={category} />
            </StyledButton>
          </SoundButtonContainer>
        )}
      </WrapperOutside>
    </WidthWrapper>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  free: PropTypes.bool,
  topic: PropTypes.string,
  type: PropTypes.string,
  category: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.object,
  ctaDetailPopin: PropTypes.array,
  detailsLabel: PropTypes.string,
  ctaButtonTheme: PropTypes.string,
  details: PropTypes.array,
  onClickDetails: PropTypes.func,
  credits: PropTypes.string,
  image: PropTypes.object.isRequired,
  imageRatio: PropTypes.number,
  animate: PropTypes.bool,
  animatedStyles: PropTypes.object.isRequired,
  showButton: PropTypes.bool,
};

Card.defaultProps = {
  animate: false,
  showButton: false,
  imageRatio: 667 / 376,
};

export default React.memo(Card);
